function MainFreeStuff() {
  return (
    <>
      <div className="block w-full flex justify-center items-center sm:w-1/2 sm:m-auto sm:flex gap-4 p-10">
        <div className="block gap-4 md:shrink m-auto p-1 border-4 border-solid rounded-lg">
          <img
            alt="audiobook"
            src="./img/Page-3/FreeStuff.jpg"
            className="text-center"
          />
        </div>
      </div>
      <div className="block md:flex gap-4 m-auto text-center">
        <a
          target="_blank"
          href="https://www.youtube.com/@kalalauspiritualpizza6462/videos"
          className="xl:text-4xl lg:text-3xl md:text-2xl text-xl text-white text-center m-auto underline underline-offset-2"
        >
          KalalauSpirtualPizza @YouTube
        </a>
      </div>
      <div className="block md:flex gap-4 m-auto text-center">
        <a
          target="_blank"
          href="https://soundcloud.com/buzzcoastin/tracks"
          className="xl:text-4xl lg:text-3xl md:text-2xl text-xl text-white text-center m-auto underline underline-offset-2"
        >
          SoundCloud/BuzzCoastin
        </a>
      </div>
      <div className="block md:flex gap-4 m-auto text-center">
        <a
          target="_blank"
          href="https://www.buzzcoastin.com/bakedspiritualpizza.pdf"
          className="xl:text-4xl lg:text-3xl md:text-2xl text-xl text-white text-center m-auto underline underline-offset-2"
        >
          Free Sample eBook Chapter
        </a>
      </div>
      <div className="block md:flex gap-4 m-auto text-center">
        <a
          target="_blank"
          href="https://soundcloud.com/buzzcoastin/tribute-to-rodney-ahn-kalalau-outlaw"
          className="xl:text-4xl lg:text-3xl md:text-2xl text-xl text-white text-center m-auto underline underline-offset-2"
        >
          Free Sample AudioBook Chapter
        </a>
      </div>
    </>
  );
}
export default MainFreeStuff;
