import MainPrintBook from "./MainPrintBook";
import BookBanner from "./BookBanner";

function Main() {
  return (
    <>
      {/* <main> */}
      <BookBanner />
      <MainPrintBook />
      {/* </main> */}
    </>
  );
}
export default Main;
