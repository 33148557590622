import React from "react";
import { Navbar, Footer, MainAboutBuzz } from "../components/index";

const AboutBuzz = () => {
  return (
    <div
      className="bg-cover bg-no-repeat"
      style={{ backgroundImage: "url('./img/background.jpg')" }}
    >
      <Navbar />
      {/* <Header img="./img/Page-3/header-image.jpg" /> */}
      <MainAboutBuzz />
      <Footer />
    </div>
  );
};

export default AboutBuzz;
