import ImgCard from "./ImgCard";
function BookBanner() {
  return (
    <div className="services w-full p-4">
      <div className="card-cover">
        {/* <div className="col-md-12"> */}
        <div className="md:shrink">
          <div className="grid grid-cols-12 gap-2">
            <div className="mb-2 col-span-12 sm:col-span-4 md:col-span-3 xl:col-span-2 flex justify-center items-center">
              <div className="w-3/4 align-middle !bg-transparent">
                <img
                  alt="card-img"
                  src="./img/Page-1/Icon.jpg"
                  className="text-center"
                />
              </div>
            </div>
            <div className="mb-2 col-span-12 sm:col-span-4 md:col-span-3 xl:col-span-2 flex md:m-auto">
              <a
                target="_blank"
                href="https://www.amazon.com/Spiritual-Pizza-Everywhere-Buzz-Coastin/dp/B0BM3FZYR4"
              >
                <ImgCard title="" img="Page-1/PrintBook.jpg" text="" goURL="" />
              </a>
            </div>
            <div className="mb-2 col-span-12 sm:col-span-4 md:col-span-3 xl:col-span-2 flex md:m-auto">
              <ImgCard
                title=""
                img="Page-1/AudioBook.jpg"
                text=""
                goURL="./audiobook"
              />
            </div>
            <div className="mb-2 col-span-12 sm:col-span-4 md:col-span-3 xl:col-span-2 flex md:m-auto">
              <ImgCard
                title=""
                img="Page-1/FreeStuff.jpg"
                text=""
                goURL="./freestuff"
              />
            </div>
            <div className="mb-2 col-span-12 sm:col-span-4 md:col-span-3 xl:col-span-2 flex md:m-auto">
              <ImgCard
                title=""
                img="Page-1/AboutBuzz.jpg"
                text=""
                goURL="./aboutbuzz"
              />
            </div>
            <div className="mb-2 col-span-12 sm:col-span-4 md:col-span-3 xl:col-span-2 flex justify-center items-center">
              <div className="w-3/4 align-middle !bg-transparent">
                <img
                  alt="card-img"
                  src="./img/Page-1/Icon.jpg"
                  className="text-center"
                />
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
export default BookBanner;
