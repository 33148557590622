import React from "react";
import { Navbar, Header, Footer, MainFreeStuff } from "../components/index";

const FreeStuff = () => {
  return (
    <div
      className="bg-cover bg-no-repeat"
      style={{ backgroundImage: "url('./img/background.jpg')" }}
    >
      <Navbar />
      <Header img="./img/Page-3/header-image.jpg" />
      <MainFreeStuff />
      <Footer />
    </div>
  );
};

export default FreeStuff;
