import { animateScroll as scroll } from "react-scroll";

function Footer() {
  return (
    <div className="h-36 pt-10 md:pt-20">
      {/* <footer> */}
      <p className="footer-text text-center">
        Copyright 2023 BUZZ Coastin - Buzz at BUZZCOASTIN.COM
      </p>
      <button onClick={() => scroll.scrollToTop(2500)} src="" className="gotop">
        <i className="fas fa-level-up-alt"></i>
      </button>
      {/* </footer> */}
    </div>
  );
}
export default Footer;
