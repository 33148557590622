import Button from "@mui/material/Button";

function MainAboutBuzz() {
  return (
    <div className="pt-20">
      <div className="block md:flex gap-4 p-10 md:max-h-96">
        <div className="block gap-4 md:shrink flex justify-center items-center md:m-auto p-1 md:max-h-80">
          <img
            alt="audiobook"
            src="./img/Page-4/AboutBuzz.jpg"
            className="text-center max-h-80 border-2 border-solid rounded-lg p-1"
          />
        </div>
        <div className="block gap-4 md:shrink flex justify-center items-center md:m-auto p-1 md:max-h-80">
          <img
            alt="audiobook"
            src="./img/Page-4/Buzz2004.jpg"
            className="text-center max-h-80 border-2 border-solid rounded-lg p-1"
          />
        </div>
        <div className="block gap-4 md:shrink md:m-auto p-1 md:max-h-80 justify-center items-center">
          <div className="block flex gap-2 p-2 max-h-80 justify-center items-center">
            <img
              alt="audiobook"
              src="./img/Page-4/CatchBuzz.jpg"
              className="text-center max-h-80 border-2 border-solid rounded-lg p-1"
            />
          </div>
          <div className="block md:flex gap-2 max-h-80 p-2 flex justify-center items-center">
            <Button
              className="btn-buy m-auto"
              variant="text"
              href="https://www.fatfreecartpro.com/i/11t0v"
              style={{
                backgroundImage: "url('./img/Page-2/Buy-Now-button.png')",
              }}
            ></Button>
          </div>
        </div>
      </div>

      <div className="block md:flex gap-4 pb-10">
        <div className="block gap-4 md:shrink m-auto p-1">
          <p className="text-3xl text-white text-center">
            Buzz Coastin on the Kalalau Trail 2004
          </p>
        </div>
      </div>
      <div className="block md:flex gap-4 px-20 pb-5 m-auto text-center">
        <p className="text-2xl text-white text-left">
          What would life be like if you chucked it all for a tropical paradise?
          Find out what happened to Buzz when he did just that at age 50!
        </p>
      </div>
      <div className="block md:flex gap-4 px-20 pb-5 m-auto text-center">
        <p className="text-2xl text-white text-left">
          Buzz was once a corporate executive livin’ high on the hog, but none
          of that success was satisfying nor did it make him happy. One day he
          found himself at a crossroad. One road led back to his old life and
          one road led into unknown territory, deep into a Hawaiian jungle.
        </p>
      </div>
      <div className="block md:flex gap-4 px-20 pb-5 m-auto text-center">
        <p className="text-2xl text-white text-left">
          In this Spiritual Pizza, Buzz tells his story of how that happened and
          what happened when he choose the Unknown Path. He talks about his
          Vision Quest and Pilgrimage hike into Kalalau Valley. There are also
          stories about his life in the jungle among the Kalalau Outlaws. There
          are some stories about the persecution of the Hawaiians living in
          Kalalau Valley by the state… the same people who stole Hawaii from the
          Hawaiians.
        </p>
      </div>
      <div className="block md:flex gap-4 px-20 pb-5 m-auto text-center">
        <p className="text-2xl text-white text-left">
          The print version of Spiritual Pizza has 150 pages and some black and
          white sketches of characters and scenes found in the book. The Kindle
          version has more pictures and some pictures are in color. The print
          book, Kindle and the Spiritual Pizza AudioBook Experience! tell the
          same story in different ways.
        </p>
      </div>
      <div className="block md:flex gap-4 px-20 pb-5 m-auto text-center">
        <p className="text-2xl text-white text-left">
          The Spiritual Pizza AudioBook Experience! has all the print book’s
          content and more. In addition to that, there are crowd sounds of
          Spiritual Pizza parties in Kalalau, Outlaw music and some Outlaws tell
          their stories found in the print book.
        </p>
      </div>
      <div className="block md:flex gap-4 px-20 pb-5 m-auto text-center">
        <p className="text-2xl text-white text-left">
          One Outlaw reviewed The Spiritual Pizza AudioBook Experience! Like
          this:
        </p>
      </div>
      <div className="block md:flex gap-4 px-20 pb-5 m-auto text-center">
        <p className="text-2xl text-white text-left">
          “It’s GENIUS the way you have real audio conversations and music
          worked in. Hearing everyone’s voice brings me straight back. IT IS SO
          KEWL! And all the details you’ve worked in really shows ‘The Bigger
          Picture’ I can’t wait for the rest. I LOVE that I know just about
          every single character in this story. This is truly EPIC to me!"
          O’okala Olla
        </p>
      </div>

      <div className="block md:flex gap-4 md:p-10 md:max-h-96">
        <div className="block gap-4 flex md:m-auto justify-center items-center p-1 md:max-h-80">
          <img
            alt="audiobook"
            src="./img/Page-4/AudioBook.jpg"
            className="text-center md:max-h-80 border-2 border-solid rounded-lg p-1"
          />
        </div>
        <div className="block gap-4 md:shrink m-auto p-1 md:max-h-80">
          <div className="block md:flex gap-2 max-h-80 p-2 flex justify-center items-center">
            <Button
              className="btn-buy m-auto"
              variant="text"
              href="https://www.fatfreecartpro.com/i/11t0v"
              style={{
                backgroundImage: "url('./img/Page-2/Buy-Now-button.png')",
              }}
            ></Button>
          </div>
        </div>
        <div className="block gap-4 md:shrink m-auto p-1 md:max-h-80 justify-center items-center">
          <p className="text-6xl font-bold text-white text-center tracking-widest leading-loose">
            $9.99
          </p>
        </div>
      </div>
    </div>
  );
}
export default MainAboutBuzz;
