import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";

function Navbar() {
  document.addEventListener("scroll", function (e) {
    if (window.screen.width < 768 && window.scrollY > 690) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");

      nav.classList.add("navopened");
    } else if (window.screen.width > 768 && window.scrollY > 220) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");

      nav.classList.add("navopened");
    } else {
      const nav = document.querySelector(".navbar");
      const gotop = document.querySelector(".gotop");
      gotop.classList.remove("display");
      nav.classList.remove("navopened");
    }
  });
  // function openBar() {
  //   const bar = document.querySelector(".bar");

  //   bar.classList.toggle("opened");
  // }

  return (
    <nav className="navbar">
      <div className="container">
        <div className="block sm:flex gap-2 mb-5">
          <h1 className="logo block flex gap-1 w-full sm:w-1/2 mb-1 mt-2">
            <ScrollLink
              spy={true}
              smooth={true}
              duration={1000}
              to="headerbg"
              style={{ cursor: "pointer" }}
            >
              Spiritual Pizza
            </ScrollLink>
          </h1>
          <ul className="bar block flex justify-center align-center gap-1 w-full sm:w-1/2 mb-1 mt-2">
            <li>
              <RouterLink to={"/"}>Home</RouterLink>
            </li>
            <li>
              <RouterLink to={"/audiobook"}>AudioBook</RouterLink>
            </li>
            <li>
              <RouterLink to={"/freestuff"}>Free Stuff</RouterLink>
            </li>
            <li>
              <RouterLink to={"/aboutbuzz"}>About Buzz</RouterLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default Navbar;
