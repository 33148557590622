import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
// import { Redirect } from "react-router";
import Home from "../pages/Home";
import AudioBook from "../pages/AudioBook";
import FreeStuff from "../pages/FreeStuff";
import AboutBuzz from "../pages/AboutBuzz";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/audiobook" element={<AudioBook />} />
        <Route exact path="/freestuff" element={<FreeStuff />} />
        <Route exact path="/aboutbuzz" element={<AboutBuzz />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
