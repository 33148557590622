function MainPrintBook() {
  return (
    <>
      <div className="about-scroll mt-px w-full h-5">
        <img
          src="../img/Page-1/sparkle.png"
          alt="sparkle.png"
          className="m-auto w-3/4"
        ></img>
      </div>

      <div className="block md:flex gap-4 p-5">
        <div className="block gap-4 md:shrink lg:w-1/2 md:w-full pb-4">
          <img
            alt="about"
            src="../img/Page-1/Book-Front.jpg"
            className="img-fluid m-auto"
          />
        </div>
        <div className="block gap-4 md:shrink lg:w-1/2 md:w-full pb-4">
          <img
            alt="about"
            src="../img/Page-1/Book-Back.jpg"
            className="img-fluid m-auto"
          />
        </div>
      </div>

      <div className="mt-px w-full h-5 mb-4">
        <img
          src="../img/Page-1/sparkle.png"
          alt="sparkle.png"
          className="m-auto w-3/4"
        ></img>
      </div>
    </>
  );
}
export default MainPrintBook;
