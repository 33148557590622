import { Link } from "react-router-dom";

function ImgCard(props) {
  return (
    <div className="imgcard p-2 min-w-min flex justify-center items-center">
      {props.goURL !== "" ? (
        <Link to={props.goURL}>
          <img
            alt="card-img"
            src={"../img/" + props.img}
            className="text-center"
          />
        </Link>
      ) : (
        <img
          alt="card-img"
          src={"../img/" + props.img}
          className="text-center"
        />
      )}
    </div>
  );
}
export default ImgCard;
